import React from "react";
import AppleStore from "../../assets/images/stores/app-store.png";
import GooglePlay from "../../assets/images/stores/google-play.png";
import CompanyFeature1 from "../../assets/images/company/Company_Feature_1.png";
import PatientPhone from "../../assets/images/patients.png";
import BannerPhone from "../../assets/images/banner-phone.png";
import QuestionsImage from "../../assets/images/questions-image.jpg";
import Number1 from "../../assets/images/numbers/number1.png";
import Number2 from "../../assets/images/numbers/number2.png";
import Number3 from "../../assets/images/numbers/number3.png";
import LeftCircle from "../../assets/images/left-circle.png";
import Header from "../../components/Header";
import FeatureItem from "../../components/1.Atoms/FeatureItem";
import { Bounce, Fade } from "react-awesome-reveal";
import { QuestionSummary } from "../../components/2.Molecules/QuestionSummary";
import { Footer } from "../../components/2.Molecules/Footer";

const Company = () => {
  const subject = encodeURIComponent("Wibof Demo Request");
  const body = encodeURIComponent(
    "I'm interested in the Wibof product for companies"
  );
  return (
    <>
      <div>
        <Header />
        <section id="home" className="company-bg">
          <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
            <div className="lg:mt-0 md:mt-0 mt-8 flex flex-col items-center mb-16 text-center lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
              <h2 className="mt-14 text-center lg:mb-4 lg:text-3xl text-lg font-light text-white">
                Wibof para Empresas:
              </h2>
              <h1 className="mb-8 text-xl lg:text-[2rem] lg:leading-[40px] leading-tight font-regular  text-[#FE805F]">
                Tu compañero ideal hacia el bienestar mental y emocional para
                empleados y organizaciones
              </h1>
              <p className="mb-8 lg:text-base text-sm font-medium leading-relaxed text-center text-white">
                Apoyamos a tus colaboradores a ser más felices y a trabajar con
                mayor motivación. ¡Únete a nuestra comunidad!
              </p>
            </div>
          </div>
        </section>
        <Fade>
          <section className="lg:-mt-[70rem] md:-mt-[70rem] -mt-[72rem]">
            <div className="p-2">
              <h2 className="lg:text-[3.2rem] text-[2rem] font-regular tracking-tighter text-[#FE805F]">
                Tu aliado hacia una mente más sana y más fuerte
              </h2>
              <div className="flex justify-center">
                <p className="mt-4 mb-8 lg:text-base max-w-[600px] text-sm font-medium leading-relaxed text-center text-[#3A538C]">
                  Wibof es una solución integral y única en el cuidado del
                  bienestar mental para empresas que acompaña a los
                  departamentos de Recursos Humanos a alcanzar sus objetivos,
                  inspirando el crecimiento de sus equipos o colaboradores.
                </p>
              </div>
            </div>
          </section>
        </Fade>

        <div>
          <div id="features">
            <div className="flex justify-center p-4">
              <div className="">
                <Fade delay={100}>
                  <section className="bg-white">
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-[20rem]"
                        src={PatientPhone}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-bold text-[#3A538C]">
                          Para empleados
                        </h2>
                        <p className="mb-4 text-left text-lg tracking-tight font-light text-[#3A538C]">
                          Nuestro equipo de terapeutas altamente calificados y
                          con basta experiencia les ofrece un apoyo confidencial
                          e integral para ayudarles en los momentos claves de
                          sus vidas, brindándoles un acompañamiento cercano.
                        </p>

                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                      </div>
                    </div>
                  </section>
                </Fade>

                <Fade delay={100}>
                  <section className="bg-white">
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl md:grid md:grid-cols-2 flex-row-reverse sm:py-16 lg:px-6">
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-bold text-[#3A538C]">
                          Para la empresa y RRHH
                        </h2>
                        <p className="mb-4 text-left text-lg tracking-tight font-light text-[#3A538C]">
                          Brindamos una solución híbrida, única, completa y
                          escalable utilizando el poder de los datos para poder
                          medir el bienestar emocional de sus colaboradores para
                          lograr un ambiente más saludable, de mayor retención y
                          mejor rendimiento.
                        </p>

                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Con Wibof, podrás medir la motivación y bienestar de tus empleados."
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={
                              "Identifica y evalúa síntomas de burnout y del estado emocional de tu equipo."
                            }
                          />
                          <FeatureItem
                            number={Number3}
                            description={
                              "Obtén información estadística sobre la salud mental de tus colaboradores para crear un ambiente laboral más positivo."
                            }
                          />
                        </div>
                      </div>
                      <img
                        draggable={false}
                        className="w-full"
                        src={CompanyFeature1}
                        alt="dashboard image"
                      />
                    </div>
                  </section>
                </Fade>

                <Fade delay={100}>
                  <section className="bg-[#3C5490] rounded-2xl">
                    <div className="py-8 px-4 mx-auto flex justify-center max-w-screen-xl sm:py-16 lg:px-6">
                      <div className="max-w-screen-md">
                        <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-100">
                          ¿Quieres mejorar el bienestar mental de tu equipo?
                        </h2>
                        <p className="mb-8 font-light text-gray-200 sm:text-xl">
                          Sé una de las empresas líderes que se ocupan del
                          bienestar de sus colaboradores.
                        </p>
                        <div className="flex flex-col justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                          <a
                            href={`mailto:melissa@wibof.com?subject=${subject}&body=${body}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white bg-[#F97F64] p-4 focus:outline-none font-medium rounded-full text-lg px-4 py-2 text-center mr-3"
                          >
                            Solicitar demo
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                </Fade>
              </div>
            </div>
          </div>

          <section className="manada-bg flex flex-col lg:flex-row items-center text-white py-16 lg:py-20 px-4 lg:px-6">
            <div className="lg:absolute left-28 sm:mt-4 lg:text-left lg:mb-0">
              <h2 className="lg:mb-10 text-3xl lg:text-[5rem] font-light">
                ¡unete a
              </h2>
              <h1 className="text-4xl lg:text-[5rem] font-medium text-[#FE805F]">
                mi manada!
              </h1>
              <p className="lg:mt-10 lg:text-md text-sm font-medium text-white">
                En ella, podrás compartir tus sentimientos y recibir apoyo: todo
                de manera anónima y segura.
              </p>
            </div>
          </section>

          <section id="questions" className="bg-[#F4F6F5] lg:-mt-16">
            <div className="absolute right-0">
              <img
                draggable={false}
                src={LeftCircle}
                alt="Imagen Izquierda"
                className="absolute z-10 top-1/2 transform w-52 h-auto lg:relative lg:left-auto"
              />
            </div>
            <div className="gap-4 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
              <img draggable={false} src={QuestionsImage} alt="Wibof" />

              <div className="mt-4 md:mt-0">
                <h1 className="lg:text-left lg:mb-8 lg:text-[3rem] text-[1.6rem] font-medium text-[#3A538C] md:text-5xl">
                  Preguntas frecuentes
                </h1>

                <div>
                  <div className="py-5">
                    <QuestionSummary
                      title={"¿Cómo puedo adquirir un paquete para mi empresa?"}
                      content={
                        "Si estás interesado en adquirir un paquete para tu empresa, por favor contáctanos en soporte@wibof.com y te proporcionaremos la información necesaria para comenzar."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cuál es el costo de los servicios para las empresas?"
                      }
                      content={
                        "El costo de los servicios para las empresas varía según la cantidad de empleados y las personalizaciones que se requieran. Después de nuestro primer encuentro, le enviaremos una propuesta detallada que incluirá los costos y los servicios específicos que ofrecemos para su empresa, además de nuestros medios de pago."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cómo se adapta el servicio a las necesidades específicas de nuestra empresa?"
                      }
                      content={
                        "Trabajaremos estrechamente con su empresa para personalizar el servicio según sus necesidades específicas. Realizaremos un primer encuentro para comprender sus requisitos y luego le proporcionaremos una propuesta ajustada a medida y un plan de implementación."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-0 rotate-180">
              <img
                draggable={false}
                src={LeftCircle}
                alt="Imagen Izquierda"
                className="absolute z-10 top-[354px] transform w-52 h-auto lg:relative lg:left-auto"
              />
            </div>
          </section>
          <Bounce>
            <div
              id="start"
              className="lg:mt-[8rem] w-full flex justify-center items-center"
            >
              <section className="w-full flex items-center max-w-screen-lg  lg:h-[20rem] bg-[#FFE2DA] rounded-xl">
                <div className="gap-24 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                  <div className="">
                    <h2 className="mb-4 lg:text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                      juntos avancemos
                    </h2>
                    <h2 className="mb-4 lg:text-left text-4xl tracking-tight font-bold text-[#F97F64]">
                      hacia tu mejor versión
                    </h2>
                    <div className="lg:text-left">
                      <small className="mb-4 lg:text-left text-sm tracking-tight font-medium text-[#3A538C]">
                        ¡Descárgala hoy mismo y comencemos!
                      </small>
                      <div className="w-full mt-2 flex flex-col mb-4 lg:flex-row">
                        <div className="w-full flex lg:justify-start justify-center items-center gap-4">
                          <a
                            href="https://apps.apple.com/do/app/wibof-terapia-psicologica/id6473286882?l"
                            rel="noreferrer"
                            className="app-download-btn"
                          >
                            <img
                              draggable={false}
                              className="w-32"
                              src={AppleStore}
                              alt=""
                            />
                          </a>
                          <a
                            type="button"
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.wibof.app"
                            rel="noreferrer"
                          >
                            <img
                              draggable={false}
                              className="w-36"
                              src={GooglePlay}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    draggable={false}
                    className="w-80"
                    src={BannerPhone}
                    alt="dashboard image"
                  />
                </div>
              </section>
            </div>
          </Bounce>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Company;
