import React from "react";
import MainPhone from "../../assets/images/main-phone.png";
import AppleStore from "../../assets/images/stores/app-store.png";
import GooglePlay from "../../assets/images/stores/google-play.png";
import Feature1 from "../../assets/images/feature-1.png";
import Feature2 from "../../assets/images/feature-2.png";
import Feature3 from "../../assets/images/feature-3.png";
import PatientPhone from "../../assets/images/patients.png";
import SpecialistPhone from "../../assets/images/specialist.png";
import CompanyPhone from "../../assets/images/company.png";
import BannerPhone from "../../assets/images/banner-phone.png";
import QuestionsImage from "../../assets/images/questions-image.jpg";
import Number1 from "../../assets/images/numbers/number1.png";
import Number2 from "../../assets/images/numbers/number2.png";
import Number3 from "../../assets/images/numbers/number3.png";
import Number4 from "../../assets/images/numbers/number4.png";
import Number5 from "../../assets/images/numbers/number5.png";
import LeftCircle from "../../assets/images/left-circle.png";
import Header from "../../components/Header";
import { Card } from "../../components/Card";
import FeatureItem from "../../components/1.Atoms/FeatureItem";
import { Bounce, Fade } from "react-awesome-reveal";
import { QuestionSummary } from "../../components/2.Molecules/QuestionSummary";
import { Footer } from "../../components/2.Molecules/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Header />
        <section id="home" className="main-bg">
          <div className="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
            <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
              <h2 className="mt-14 lg:mb-4 lg:text-5xl text-lg font-light text-white">
                tu compañero hacia
              </h2>
              <h1 className="mb-8 text-[2rem] lg:text-[4rem] lg:leading-[60px] leading-tight font-regular text-[#FE805F]">
                una mente más <br /> fuerte y sana
              </h1>
              <p className="mb-8 lg:text-base text-sm font-medium leading-relaxed text-left text-white">
                ¡Descárgala hoy mismo y comencemos el viaje hacia la salud
                mental y el bienestar!
              </p>

              <div className="flex flex-col justify-center lg:flex-row">
                <div className="flex items-center gap-6">
                  <a
                    type="button"
                    target="_blank"
                    href="https://apps.apple.com/do/app/wibof-terapia-psicologica/id6473286882?l"
                    rel="noreferrer"
                  >
                    <img
                      className="w-32"
                      draggable={false}
                      src={AppleStore}
                      alt="Logo App Store"
                    />
                  </a>
                  <a
                    type="button"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.wibof.app"
                    rel="noreferrer"
                  >
                    <img
                      className="w-36"
                      draggable={false}
                      src={GooglePlay}
                      alt="Logo Google Play"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/3 lg:max-w-lg md:w-1/2">
              <img
                className="object-cover object-center rounded-lg w-[24rem]"
                alt="Hero"
                draggable={false}
                src={MainPhone}
              />
            </div>
          </div>
        </section>
        <Fade>
          <section
            id="about"
            className="lg:-mt-[102rem] md:-mt-[102rem] -mt-[84rem]"
          >
            <div className="p-2">
              <h2 className="lg:text-[4rem] text-[2rem] font-regular tracking-tighter text-[#FE805F]">
                un espacio
              </h2>
              <h1 className="mb-8 lg:text-[3rem] text-[1.4rem] font-light text-[#3A538C]">
                para todos
              </h1>
            </div>
            <div className="flex justify-center p-4">
              <div className="flex flex-wrap gap-6 justify-around">
                <Card
                  image={Feature1}
                  title={"Pacientes"}
                  description={
                    "Adquiere los recursos, herramientas y la manada que necesitas para cultivar y mantener tu bienestar."
                  }
                />
                <Card
                  image={Feature2}
                  title={"Psicólogos"}
                  description={
                    "Te apoyo a ser encontrado, me encargo de tus citas, de tu cobro automatizado y de tu panel informativo."
                  }
                />
                <Card
                  image={Feature3}
                  title={"Instituciones"}
                  description={
                    "Te ayudo a medir la motivación, productividad y el bienestar mental de tu gente, ayudándoles a sentir que pertenecen y son apreciados."
                  }
                />
              </div>
            </div>
          </section>
        </Fade>

        <div>
          <div id="features">
            <div className="mt-10 p-2">
              <h2 className="lg:mb-2 lg:text-[3rem] text-[1.2rem] font-light tracking-tighter text-[#3A538C] md:text-4xl">
                ¡Bienvenido a tu
              </h2>
              <h1 className="mb-8 lg:text-[3rem] text-[1.6rem] font-medium text-[#FE805F] md:text-5xl">
                lugar seguro!
              </h1>
            </div>

            <div className="flex justify-center p-4">
              <div className="">
                <Fade delay={100}>
                  <section className="bg-white hover:cursor-pointer">
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-72"
                        src={PatientPhone}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                          Pacientes
                        </h2>
                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Visualiza tu contenido de manera personalizada y automatizada"
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={"Únete a la manda de apoyo"}
                          />
                          <FeatureItem
                            number={Number3}
                            description={"Conéctate con psicólogos expertos"}
                          />
                          <FeatureItem
                            number={Number4}
                            description={
                              "Programa tus citas de manera sencilla"
                            }
                          />
                          <FeatureItem
                            number={Number5}
                            description={
                              "Accede a recursos que te apoyarán a mantener tu bienestar"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Fade>
                <Fade delay={100}>
                  <section
                    className="bg-white hover:cursor-pointer"
                    onClick={() => navigate("/specialists")}
                  >
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-72 lg:hidden md:hidden block"
                        src={SpecialistPhone}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                          Psicólogos
                        </h2>
                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Toda tu práctica terapéutica, centralizada en un mismo lugar"
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={"Acompaña a tus pacientes"}
                          />
                          <FeatureItem
                            number={Number3}
                            description={
                              "Conecta con nuevos pacientes que buscan apoyo"
                            }
                          />
                          <FeatureItem
                            number={Number4}
                            description={
                              "Simplifica tu trabajo con herramientas como calendario de citas, cobro automatizado, y paneles informativos"
                            }
                          />
                        </div>
                      </div>
                      <img
                        draggable={false}
                        className="w-72 hidden lg:block"
                        src={SpecialistPhone}
                        alt="dashboard image"
                      />
                    </div>
                  </section>
                </Fade>
                <Fade delay={100}>
                  <section
                    className="bg-white hover:cursor-pointer"
                    onClick={() => navigate("/company")}
                  >
                    <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                      <img
                        draggable={false}
                        className="w-72"
                        src={CompanyPhone}
                        alt="dashboard image"
                      />
                      <div className="mt-4 md:mt-0">
                        <h2 className="mb-4 text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                          Instituciones
                        </h2>
                        <hr className="-mt-2 border-t-4 w-20 border-[#FE805F]" />
                        <div className="mt-6">
                          <FeatureItem
                            number={Number1}
                            description={
                              "Con Wibof, podrás medir la motivación y bienestar de tus empleados"
                            }
                          />
                          <FeatureItem
                            number={Number2}
                            description={
                              "Identifica y evalúa síntomas de burnout y del estado emocional de tu equipo"
                            }
                          />
                          <FeatureItem
                            number={Number3}
                            description={
                              "Obtén información estadística sobre la salud mental de tus colaboradores para crear un ambiente laboral más positivo"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </Fade>
              </div>
            </div>
          </div>

          <section className="manada-bg flex flex-col lg:flex-row items-center text-white py-16 lg:py-20 px-4 lg:px-6">
            <div className="lg:absolute left-28 sm:mt-4 lg:text-left lg:mb-0">
              <h2 className="lg:mb-10 text-3xl lg:text-[5rem] font-light">
                ¡unete a
              </h2>
              <h1 className="text-4xl lg:text-[5rem] font-medium text-[#FE805F]">
                mi manada!
              </h1>
              <p className="lg:mt-10 lg:text-md text-sm font-medium text-white">
                En ella, podrás compartir tus sentimientos y recibir apoyo: todo
                de manera anónima y segura.
              </p>
            </div>
          </section>

          <section id="questions" className="bg-[#F4F6F5] lg:-mt-16">
            <div className="absolute right-0">
              <img
                draggable={false}
                src={LeftCircle}
                alt="Imagen Izquierda"
                className="absolute z-10 top-1/2 transform w-52 h-auto lg:relative lg:left-auto"
              />
            </div>
            <div className="gap-4 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
              <img draggable={false} src={QuestionsImage} alt="Wibof" />

              <div className="mt-4 md:mt-0">
                <h1 className="relative z-10 lg:text-left lg:mb-8 lg:text-[3rem] text-[1.6rem] font-medium text-[#3A538C] md:text-5xl">
                  Preguntas frecuentes
                </h1>

                <div>
                  <div className="py-5">
                    <QuestionSummary
                      title={
                        "¿Cuáles son los requisitos para convertirse en terapeuta en la plataforma?"
                      }
                      content={
                        "Para unirte como terapeuta en nuestra plataforma, debes cumplir con requisitos básicos de licenciatura y experiencia en salud mental. Por favor, completa nuestro formulario en "
                      }
                      link={
                        <a
                          href="https://forms.gle/GxsxkWCoAdVVUjPS8"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://forms.gle/GxsxkWCoAdVVUjPS8
                        </a>
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cómo puedo actualizar mi perfil y mi información profesional?"
                      }
                      content={
                        "Puedes encontrar tu perfil y tu información profesional en la sección 'Mi perfil' de la aplicación. Aquí podrás agregar detalles sobre tu experiencia, habilidades y enfoques terapéuticos."
                      }
                    />
                    <QuestionSummary
                      title={"Si no pasa mi tarjeta, ¿qué debo hacer?"}
                      content={
                        "Si hay algún problema con tu pago, te enviaremos un correo electrónico para que puedas actualizar tus datos. Si deseas darte de baja como terapeuta, por favor envía un correo a soporte@wibof.com"
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Puedo cambiar la forma de pago que utilizo para los servicios terapéuticos?"
                      }
                      content={
                        "Sí. Para hacerlo, simplemente envía un correo a soporte@wibof.com y te ayudaremos con el proceso."
                      }
                    />
                    <QuestionSummary
                      title={"¿Cómo puedo reactivar mi cuenta de terapeuta?"}
                      content={
                        "Puedes reactivar tu cuenta enviando un correo a soporte@wibof.com Estaremos encantados de ayudarte a volver a utilizar nuestros servicios."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cómo puedo cambiar a mi terapeuta si siento que no es el adecuado para mí?"
                      }
                      content={
                        "Si sientes que no tienes una buena conexión con tu terapeuta actual, puedes solicitar un cambio de terapeuta en la plataforma. Por favor, contáctanos a través de la opción de soporte en la aplicación, y estaremos encantados de ayudarte a encontrar un terapeuta más adecuado para tus necesidades."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Hay algún costo adicional por utilizar la plataforma más allá de las sesiones terapéuticas?"
                      }
                      content={
                        "La mayoría de las funciones de la plataforma están incluidas en la tarifa de las sesiones terapéuticas. Sin embargo, hay servicios adicionales, como materiales educativos o grupos de apoyo específicos, que podrían tener un costo adicional. Para solicitar una cita, debes ir al perfil del terapeuta de tu elección y buscar un espacio disponible en su calendario. Desde allí, podrás programar tu cita de manera sencilla."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Qué sucede si un terapeuta cancela su cuenta antes de mi cita programada?"
                      }
                      content={
                        "Si un terapeuta cancela su cuenta antes de tu cita programada, te notificaremos por correo electrónico para que puedas seleccionar otro terapeuta disponible para tu cita. Queremos asegurarnos de que recibas la atención que necesitas."
                      }
                    />
                    <QuestionSummary
                      title={"¿Qué hago si tengo un problema con mi terapeuta?"}
                      content={
                        "Si tienes una inquietud sobre el trato de tu terapeuta o consideras que ha habido un incumplimiento en el código de ética, por favor escribe un correo a soporte@wibof.com Estamos aquí para ayudarte."
                      }
                    />
                    <QuestionSummary
                      title={"¿Cómo puedo adquirir un paquete para mi empresa?"}
                      content={
                        "Si estás interesado en adquirir un paquete para tu empresa, por favor contáctanos en soporte@wibof.com y te proporcionaremos la información necesaria para comenzar."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cuál es el costo de los servicios para las empresas?"
                      }
                      content={
                        "El costo de los servicios para las empresas varía según la cantidad de empleados y las personalizaciones que se requieran. Después de nuestro primer encuentro, le enviaremos una propuesta detallada que incluirá los costos y los servicios específicos que ofrecemos para su empresa."
                      }
                    />
                    <QuestionSummary
                      title={
                        "¿Cómo se adapta el servicio a las necesidades específicas de nuestra empresa?"
                      }
                      content={
                        "Trabajaremos estrechamente con su empresa para personalizar el servicio según sus necesidades específicas. Realizaremos un primer encuentro para comprender sus requisitos y luego le proporcionaremos una propuesta ajustada a medida y un plan de implementación."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-0 rotate-180">
              <img
                draggable={false}
                src={LeftCircle}
                alt="Imagen Izquierda"
                className="absolute z-10 top-[354px] transform w-52 h-auto lg:relative lg:left-auto"
              />
            </div>
          </section>
          <Bounce>
            <div
              id="start"
              className="lg:mt-[8rem] w-full flex justify-center items-center"
            >
              <section className="w-full flex items-center max-w-screen-lg  lg:h-[20rem] bg-[#FFE2DA] rounded-xl">
                <div className="gap-24 items-center py-8 px-4 mx-auto max-w-screen-lg md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                  <div className="">
                    <h2 className="mb-4 lg:text-left text-4xl tracking-tight font-medium text-[#3A538C]">
                      juntos avancemos
                    </h2>
                    <h2 className="mb-4 lg:text-left text-4xl tracking-tight font-bold text-[#F97F64]">
                      hacia tu mejor versión
                    </h2>
                    <div className="lg:text-left">
                      <small className="mb-4 lg:text-left text-sm tracking-tight font-medium text-[#3A538C]">
                        ¡Descárgala hoy mismo y comencemos!
                      </small>
                      <div className="w-full mt-2 flex flex-col mb-4 lg:flex-row">
                        <div className="w-full flex lg:justify-start justify-center items-center gap-4">
                          <a
                            type="button"
                            target="_blank"
                            href="https://apps.apple.com/do/app/wibof-terapia-psicologica/id6473286882?l"
                            className="app-download-btn"
                          >
                            <img
                              draggable={false}
                              className="w-32"
                              src={AppleStore}
                              alt=""
                            />
                          </a>
                          <a
                            type="button"
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.wibof.app"
                            rel="noreferrer"
                          >
                            <img
                              draggable={false}
                              className="w-36"
                              src={GooglePlay}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    draggable={false}
                    className="w-80"
                    src={BannerPhone}
                    alt="dashboard image"
                  />
                </div>
              </section>
            </div>
          </Bounce>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
