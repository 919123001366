import React from "react";
import { Props } from "./types";

const FeatureItem = ({ number, description }: Props) => {
  return (
    <div className="flex items-center mb-4">
      <img src={number} className="w-10 h-10 bg-[#FE805F] rounded-full mr-2" />

      <div className="text-left">
        <span className="text-[#74828B] text-sm font-light">{description}</span>
      </div>
    </div>
  );
};
export default FeatureItem;
